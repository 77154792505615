<template>
  <div class="map-box">
    <div class="text">占比越大，颜色越深</div>
    <div id="ChinaMap" :style="{ height: '521px', width: '100%' }" ref="myEchart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
const chinaJson = require("./chinaChange.json");
export default {
  name: "ChinaMap",
  props: {
    mapArr: {
      type: Array,
    },
  },
  data() {
    return {
      // mapData: [
      //   { name: "河北", value: 10000 },
      //   { name: "山西", value: 200 },
      //   { name: "辽宁", value: 4000 },
      //   { name: "吉林", value: 5000 },
      //   { name: "黑龙江", value: 6000 },
      //   { name: "江苏", value: 2000 },
      //   { name: "浙江", value: 800 },
      //   { name: "安徽", value: 2000 },
      //   { name: "福建", value: 4600 },
      //   { name: "江西", value: 3200 },
      //   { name: "山东", value: 200 },
      //   { name: "河南", value: 200 },
      //   { name: "湖北", value: 2060 },
      //   { name: "湖南", value: 3000 },
      //   { name: "广东", value: 2900 },
      //   { name: "海南", value: 2000 },
      //   { name: "四川", value: 2120 },
      //   { name: "贵州", value: 20 },
      //   { name: "云南", value: 2000 },
      //   { name: "陕西", value: 2890 },
      //   { name: "甘肃", value: 2740 },
      //   { name: "青海", value: 2660 },
      //   { name: "台湾", value: 2440 },
      //   { name: "内蒙古", value: 2350 },
      //   { name: "广西", value: 2700 },
      //   { name: "西藏", value: 2000 },
      //   { name: "宁夏", value: 2000 },
      //   { name: "新疆", value: 2000 },
      //   { name: "北京", value: 2000 },
      //   { name: "天津", value: 2000 },
      //   { name: "上海", value: 2000 },
      //   { name: "重庆", value: 2000 },
      //   { name: "香港", value: 2000 },
      //   { name: "澳门", value: 2000 },
      //   { name: "南海诸岛", value: 800 },
      // ],
      mapData: this.mapArr,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(data) {
      let myChart = echarts.init(document.getElementById("ChinaMap"));
      echarts.registerMap("china", chinaJson);
      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (!params.value) {
              return "该地区城市占比为0";
            }
            return "城市占比" + "<br />" + params.name + "：" + params.value + "%";
          },
          confine: true,
        },
        visualMap: {
          min: 0,
          max: 100,
          // text: ['高', '低'],
          realtime: false,
          calculable: false,
          itemWidth: 16,
          itemHeight: 79,
          left: 0,
          top: 40,
          inverse: true,
          orient: "horizontal",
          inRange: {
            color: ["#E7F0FF", "#0955CE"],
          },
        },
        series: [
          {
            name: "",
            type: "map",
            // zoom: 1.4,
            label: {
              normal: {
                show: false,
              },
            },
            zoom: 1, //当前视角的缩放比例
            roam: true, //是否开启平游或缩放
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 1,
              max: 10,
            },
            mapType: "china", // 自定义扩展图表类型
            itemStyle: {
              // normal: {label: {show: true}},
              emphasis: { label: { show: true } },
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    color: "#444",
                  },
                },
              },
            },
            data: this.mapData,
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.on("mouseover", function (params) {
        if (params.data.value != undefined) {
          myChart.dispatchAction({
            type: "downplay",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.map-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  position: relative;
  .text {
    position: absolute;
    color: #999;
    top: 18px;
    left: 5px;
  }
}
</style>
