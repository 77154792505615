<template>
  <div class="container-box">
    <component-data-board :data-board="dataBoard_1"></component-data-board>
    <div class="main-title">本月咨询人数趋势图</div>
    <div class="echart-box" id="tendencyChart" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    <component-data-board :data-board="dataBoard_2"></component-data-board>
    <div class="main-title">客户数据表</div>
    <el-table :data="tableData" style="width: 100%" :max-height="640" :header-cell-style="headCellStyle" :cell-style="cellStyle">
      <el-table-column prop="name" label="客服账号"></el-table-column>
      <el-table-column prop="new" label="售前咨询人数"></el-table-column>
      <el-table-column prop="old" label="售后咨询人数"></el-table-column>
      <el-table-column prop="order" label="订单量"></el-table-column>
      <el-table-column prop="money" label="客服销售额(￥)"></el-table-column>
      <el-table-column prop="three" label="3分钟内未回复人数"></el-table-column>
      <el-table-column prop="three_ratio" label="3分钟内人工回复率"></el-table-column>
      <el-table-column prop="average_user" label="平均人工响应时长"></el-table-column>
      <el-table-column prop="dispute" label="纠纷退款数"></el-table-column>
      <el-table-column prop="complaint" label="投诉数"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import ComponentDataBoard from "./ComponentDataBoard.vue";
import { getServiceData } from "../../../utils/apis";

export default {
  name: "ServiceDataOverview",
  components: {
    ComponentDataBoard,
  },
  data() {
    return {
      dataBoard_1: [
        {
          title: "客服概况",
          size: 45,
          content: [
            { title: "店铺本月咨询人数", num: void 0, percentage: void 0, icon: "&#xe65a;", color: "#1677FF", text_color: "#1677FF" },
            { title: "售前接待人数", num: void 0, icon: "&#xe652;", color: "#FD7E14", text_color: "#FD7E14" },
            { title: "售后接待人数", num: void 0, icon: "&#xe682;", color: "#28A745", text_color: "#28A745" },
            { title: "3分钟内回复率", num: void 0, percentage: void 0, icon: "&#xe6ca;", color: "#6F42C1", text_color: "#6F42C1" },
            { title: "平均人工响应时长", average_num: void 0, average_time: void 0, icon: "&#xe65e;", color: "#DC3545", text_color: "#DC3545" },
          ],
        },
      ],
      dataBoard_2: [
        {
          title: "客户数据概况",
          no_icon: true,
          content: [
            { title: "客服服务投诉率", num: void 0, percentage: void 0, text_color: "#1677FF" },
            { title: "客户满意度", num: void 0, percentage: void 0, text_color: "#1677FF" },
            { title: "响应流失客户率", num: void 0, percentage: void 0, text_color: "#1677FF" },
            { title: "咨询下单量", num: void 0, percentage: void 0, text_color: "#1677FF" },
            { title: "客户回访率", num: void 0, percentage: void 0, text_color: "#1677FF" },
            { title: "客户交易转化率", num: void 0, text_color: "#1677FF" },
            { title: "客单价", num: void 0, text_color: "#1677FF" },
          ],
        },
      ],
      tableData: void 0,
      headCellStyle: { color: "#35323B", background: "#F2F2F6", height: "60px", fontWeight: 500 },
      cellStyle: { color: "#626674", height: "58px" },
      option: {
        title: {
          text: "单位（人次）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let res = "时间：" + params[0].name + "日";
            for (let i = 0; i < params.length; i++) {
              res += "<br/>" + params[i].marker + params[i].seriesName + " : " + params[i].value;
            }
            return res;
          },
        },
        legend: {
          data: ["售前", "售后"],
          right: 30,
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "售前",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
            areaStyle: {
              opacity: 0.1,
            },
          },
          {
            name: "售后",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#34C759",
            },
            areaStyle: {
              opacity: 0.1,
            },
          },
        ],
      },
      loadingVal: true,
    };
  },
  methods: {
    async getServiceDetail() {
      let res = await getServiceData({});
      let dataBoardText_1 = [
        { index: 0, text: "month_num", sort: 0 },
        { index: 0, text: "three_ratio", sort: 3 },
      ];
      dataBoardText_1.map((o) => {
        this.dataBoard_1[o.index].content[o.sort].num = o.text === "three_ratio" ? `${res.data[o.text].num}%` : res.data[o.text].num;
        this.dataBoard_1[o.index].content[o.sort].percentage = res.data[o.text].month;
      });
      this.dataBoard_1[0].content[1].num = res.data.new_num;
      this.dataBoard_1[0].content[2].num = res.data.old_num;
      this.dataBoard_1[0].content[4].average_num = res.data.response.average_user;
      this.dataBoard_1[0].content[4].average_time = `上月平均响应时长：${res.data.response.average_month}`;
      this.option.series[0].data = res.data.new_trend;
      this.option.series[1].data = res.data.old_trend;
      let dataBoardText_2 = [
        { index: 0, text: "complaint", sort: 0 },
        { index: 0, text: "satisfaction", sort: 1 },
        { index: 0, text: "run", sort: 2 },
        { index: 0, text: "buy", sort: 3 },
        { index: 0, text: "interview", sort: 4 },
      ];
      dataBoardText_2.map((o) => {
        // this.dataBoard_2[o.index].content[o.sort].num = o.text === "buy" ? res.data[o.text].num : `${res.data[o.text].num}%`;
        this.dataBoard_2[o.index].content[o.sort].num = `${res.data[o.text].num}%`;
        this.dataBoard_2[o.index].content[o.sort].percentage = res.data[o.text].month;
      });
      this.dataBoard_2[0].content[5].num = `${res.data.ratio}%`;
      this.dataBoard_2[0].content[6].num = res.data.price;
      let tableArr = res.data.service_data;
      tableArr.map((o) => {
        Object.keys(o).forEach((key) => {
          if (key === "three" || key === "three_ratio" || key === "average_user" || key === "complaint") {
            if (!o[key]) o[key] = 0;
          } else {
            if (key === "dispute" && !o[key]) {
              o.name.indexOf("售前") !== -1 ? (o[key] = "/") : (o[key] = 0);
            } else {
              if (!o[key]) o[key] = "/";
            }
          }
        });
      });
      this.tableData = res.data.service_data;
    },
  },
  mounted() {
    this.tendencyChart = this.$echarts.init(document.getElementById("tendencyChart"));
    this.getServiceDetail();
    setTimeout(() => {
      this.tendencyChart.setOption(this.option);
      if (this.option.series[0].data) this.loadingVal = false;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .echart-box {
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 30px;
    padding: 30px;
  }
  /deep/ .el-table_1_column_1 {
    padding-left: 30px !important;
  }
}
</style>
