<template>
  <div class="container-box">
    <div class="echarts-box">
      <span class="main-title">年龄占比</span>
      <span class="main-title"> 性别占比</span>
      <span class="main-title">客户人群分类</span>
    </div>
    <div class="echarts-box">
      <div id="ageOf" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
      <div id="sexOf" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
      <div id="customerClassify" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    </div>
    <div class="main-title">城市占比</div>
    <div class="city-box">
      <info-map :map-arr="mapData" v-if="mapData.length > 0"></info-map>
      <div>
        <div class="flip-over" v-show="mapTableData.length > 1">
          <div @click="changeNum('down')"><i class="iconfont" v-html="'&#xe8f1;'"></i></div>
          <div @click="changeNum('up')" style="margin-right: 10px"><i class="iconfont" v-html="'&#xe8ef;'"></i></div>
        </div>
        <div v-for="(data, sort) in mapTableData[pageNum]" class="progress-box">
          <div style="width: 25px">{{ sort + 1 }}</div>
          <div style="width: 60px">{{ data.name }}</div>
          <el-progress :percentage="(data.value / mapTableData[0][0].value) * 100" style="width: 75%" :stroke-width="16" :show-text="false"> </el-progress>
          <span style="margin-left: 12px">{{ data.value }}%</span>
        </div>
      </div>
    </div>
    <div class="echarts-box">
      <span class="main-title">促销敏感度</span>
      <span class="main-title">用户活跃时间</span>
    </div>
    <div class="echarts-box">
      <div id="salesSensitivity" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
      <div id="activeTime" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    </div>
  </div>
</template>
<script>
import InfoMap from "./InfoMap";
import { getPeopleFace } from "../../../utils/apis";

export default {
  name: "PortraitCrowd",
  components: {
    InfoMap,
  },
  data() {
    return {
      customerClassifyName: [],
      customerClassifyData: {
        radar: {},
        legend: {
          bottom: 0,
        },
        series: [
          {
            type: "radar",
            symbol: "circle",
            data: [
              {
                name: "人群占比",
                itemStyle: {
                  normal: {
                    color: "#34C759",
                    lineStyle: {
                      color: "#34C759",
                    },
                  },
                },
              },
              {
                name: "高价值用户占比",
                itemStyle: {
                  normal: {
                    color: "#6B9EFF",
                    lineStyle: {
                      color: "#6B9EFF",
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      activeTimeData: {
        title: {
          text: "单位（人次）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let res = "时间：" + params[0].name + "时";
            for (let i = 0; i < params.length; i++) {
              res += "<br/>" + params[i].marker + params[i].seriesName + " : " + params[i].value;
            }
            return res;
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "人数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#FB7854",
            },
            areaStyle: {
              opacity: 0.1,
            },
          },
        ],
      },
      salesSensitivityData: {
        title: {
          text: "敏感度",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        color: ["#34C759"],
        xAxis: [
          {
            type: "category",
            data: ["不敏感", "轻度敏感", "中度敏感", "高度敏感", "极度敏感"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "敏感度",
            type: "bar",
            barWidth: 32,
          },
        ],
      },
      ageOfData: {
        color: ["#5C8EEF", "#4CD7F6", "#00C7C7", "#9C71E2", "#6DBFFF", "#DA5CEF", "#FF845D", "#F59940", "#00C696", "#68CF57"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            roseType: "radius",
            radius: ["50%", "70%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: "{c}%\n{b}",
                  shadowBlur: 200,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
                labelLine: { show: true },
              },
            },
          },
        ],
      },
      sexOfData: {
        color: ["#6B9EFF", "#B495FF"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            roseType: "radius",
            radius: ["50%", "70%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: "{c}%\n{b}",
                },
                labelLine: { show: true },
              },
            },
          },
        ],
      },
      pageNum: 0,
      mapData: [],
      mapTableData: [],
      loadingVal: true,
    };
  },
  methods: {
    chunkArr(arr, num) {
      let newArr = [];
      for (let i = 0; i < arr.length; ) {
        newArr.push(arr.slice(i, (i += num)));
      }
      return newArr;
    },
    async getPortraitCrowd() {
      let res = await getPeopleFace({});
      res.data.age.map((o) => {
        o.name = `${o.min}-${o.max}岁`;
        o.value = o.ratio;
      });
      this.ageOfData.series[0].data = res.data.age;
      let sexArr = [];
      Object.keys(res.data.ratio).map((o) => sexArr.push({ value: res.data.ratio[o], name: o === "man" ? "男" : "女" }));
      this.sexOfData.series[0].data = sexArr;
      let sensitiveArr = [];
      Object.keys(res.data.sale).map((o) => sensitiveArr.push(res.data.sale[o]));
      this.salesSensitivityData.series[0].data = sensitiveArr;
      this.activeTimeData.series[0].data = res.data.time;
      let peopleArr = [];
      let userArr = [];
      res.data.client.map((o) => {
        o.max = 100;
        peopleArr.push(o.people);
        userArr.push(o.user);
        this.customerClassifyName.push(o.name);
      });
      this.customerClassifyData.radar.indicator = res.data.client;
      this.customerClassifyData.series[0].data[0].value = peopleArr;
      this.customerClassifyData.series[0].data[1].value = userArr;
      this.customerClassifyData.tooltip = {
        confine: true,
        trigger: "item",
        formatter: (params) => {
          let res = params.name;
          let nameList = this.customerClassifyName;
          for (let i = 0; i < params.data.value.length; i++) {
            res += "<br/>" + nameList[i] + " : " + params.data.value[i] + " " + "%";
          }
          return res;
        },
      };
      res.data.city.map((o) => (o.value = o.ratio));
      this.mapData = res.data.city;
      this.mapTableData = res.data.city.filter((o) => o.value !== 0);
      this.mapTableData = this.chunkArr(this.mapTableData, 10);
      console.log({ mapData: this.mapData, mapTableData: this.mapTableData });
    },
    changeNum(type) {
      if (type === "up") {
        this.pageNum === 0 ? this.$message.error("当前已经是第一页") : this.pageNum--;
      } else {
        this.pageNum >= this.mapTableData.length - 1 ? this.$message.error("当前已经是最后一页") : this.pageNum++;
      }
    },
  },
  mounted() {
    this.customerClassify = this.$echarts.init(document.getElementById("customerClassify"));
    this.activeTime = this.$echarts.init(document.getElementById("activeTime"));
    this.salesSensitivity = this.$echarts.init(document.getElementById("salesSensitivity"));
    this.ageOf = this.$echarts.init(document.getElementById("ageOf"));
    this.sexOf = this.$echarts.init(document.getElementById("sexOf"));
    this.getPortraitCrowd();
    setTimeout(() => {
      this.customerClassify.setOption(this.customerClassifyData);
      this.activeTime.setOption(this.activeTimeData);
      this.salesSensitivity.setOption(this.salesSensitivityData);
      this.ageOf.setOption(this.ageOfData);
      this.sexOf.setOption(this.sexOfData);
      if (this.activeTimeData.series[0].data) this.loadingVal = false;
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 10px;
  }
  .echarts-box {
    display: flex;
    > div {
      flex: 1;
      margin: 0 10px 24px 0;
      background: #ffffff;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      height: 320px;
      padding: 24px;
    }
    > span {
      flex: 1;
    }
  }
  .city-box {
    background: #ffffff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    height: 575px;
    padding: 50px;
    margin-bottom: 24px;
    display: flex;
    // align-items: center;
    > div {
      flex: 1;
    }
    .flip-over {
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      margin-bottom: 36px;
      > div {
        width: 30px;
        height: 30px;
        background: #2f89f2;
        float: right;
        cursor: pointer;
      }
    }
    .progress-box {
      display: flex;
      margin-bottom: 24px;
      color: #999999;
      line-height: 16px;
    }
  }
}
</style>
