<template>
  <div class="container-box">
    <component-data-board :data-board="dataBoard"></component-data-board>
    <div class="echart-box" id="tendencyChart" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    <div class="echarts-box">
      <span class="main-title">近30天销售量</span>
      <span class="main-title">销售类别占比</span>
    </div>
    <div class="echarts-box">
      <div id="monthlySales" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
      <div id="categorySales" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    </div>
    <div class="echarts-box">
      <span class="main-title">销售额趋势</span>
      <span class="main-title">店铺热门商品TOP5</span>
    </div>
    <div class="echarts-box">
      <div id="salesTrend" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
      <div class="table-box">
        <div class="table-item" v-for="(item, index) in goodTable">
          <div>
            <img v-if="index === 0" src="../../../assets/images/no_1.png" alt="" />
            <img v-if="index === 1" src="../../../assets/images/no_2.png" alt="" />
            <img v-if="index === 2" src="../../../assets/images/no_3.png" alt="" />
            <span v-if="index !== 0 && index !== 1 && index !== 2">{{ index + 1 }}</span>
          </div>
          <div>
            <img src="../../../assets/images/no_good.png" alt="" v-if="!item.img" class="img" />
            <img :src="item.img" alt="" v-if="item.img" class="img" />
            <div class="text-box">
              <el-tooltip :content="item.goods_name" placement="top-start">
                <div class="title">{{ item.goods_name }}</div>
              </el-tooltip>
              <div>
                单价：<span class="red-text">{{ item.price }}</span>
              </div>
              <div>
                分类：<span>{{ item.category }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ComponentDataBoard from "./ComponentDataBoard.vue";
import { getProductData } from "../../../utils/apis";

export default {
  name: "ProductData",
  components: {
    ComponentDataBoard,
  },
  data() {
    return {
      goodTable: [],
      dataBoard: [
        {
          title: "店铺概况",
          size: 60,
          content: [
            { title: "商品访客数", num: void 0, percentage: void 0, icon: "&#xe651;", color: "#1677FF", text_color: "#1677FF" },
            { title: "商品浏览数", num: void 0, percentage: void 0, icon: "&#xe65c;", color: "#FDA600", text_color: "#FDA600" },
            { title: "成交转化率", num: "10%", icon: "&#xe65d;", color: "#DC3545", text_color: "#DC3545" },
          ],
        },
      ],
      option: {
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let res = "时间：" + params[0].name + "日";
            for (let i = 0; i < params.length; i++) {
              res += "<br/>" + params[i].marker + params[i].seriesName + " : " + params[i].value;
            }
            return res;
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
        },
        yAxis: {
          type: "value",
        },
      },
      tendencyChartData: {
        title: {
          text: "单位（人次）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        legend: {
          data: ["浏览数", "访问数"],
          right: 30,
        },
        series: [
          {
            name: "浏览数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
          },
          {
            name: "访问数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#34C759",
            },
          },
        ],
      },
      salesTrendData: {
        title: {
          text: "单位（元）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        series: [
          {
            name: "销售额趋势",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
            areaStyle: {
              opacity: 0.1,
            },
          },
        ],
      },
      monthlySalesData: {
        title: {
          text: "单位（件）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          right: 30,
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        color: ["#0A84FF", "#34C759"],
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: [
          {
            name: "销售额",
            type: "value",
          },
          {
            name: "销售量",
            type: "value",
          },
        ],
        series: [
          {
            name: "销售额",
            type: "bar",
            barGap: "0%",
            barWidth: 20,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0],
              },
            },
            data: [],
          },
          {
            name: "销售量",
            type: "bar",
            barGap: "0%",
            barWidth: 20,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0],
              },
            },
            data: [],
          },
        ],
      },
      categorySalesData: {
        color: ["#5C8EEF", "#4CD7F6", "#00C7C7", "#9C71E2", "#6DBFFF", "#DA5CEF", "#FF845D", "#F59940", "#00C696", "#68CF57"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "50%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: "{c}%\n{b}",
                  shadowBlur: 200,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
                labelLine: { show: true },
              },
            },
          },
        ],
      },
      loadingVal: true,
      nameArr: []
    };
  },
  methods: {
    async getProductDetail() {
      let res = await getProductData({});
      this.dataBoard[0].content[0].num = res.data.goods_visit.num;
      this.dataBoard[0].content[0].percentage = res.data.goods_visit.month;
      this.dataBoard[0].content[1].num = res.data.goods_browse.num;
      this.dataBoard[0].content[1].percentage = res.data.goods_browse.month;
      this.dataBoard[0].content[2].num = `${res.data.ratio}%`;
      this.tendencyChartData.series[0].data = res.data.browse_trend;
      this.tendencyChartData.series[1].data = res.data.visit_trend;
      this.salesTrendData.series[0].data = res.data.sale_money;
      res.data.category_ratio.map((o) => (o.value = o.num));
      this.categorySalesData.series[0].data = res.data.category_ratio;
      res.data.sale_trend.map((o) => this.nameArr.push(o.goods_name));
      this.nameArr.forEach((item) => {
        var ret = ""; //拼接加\n返回的类目项
        var max = 10;  //每行显示的文字字数
        var val = item.length;  //X轴内容的文字字数
        var rowN = Math.ceil(val / max);  //需要换的行数
        if(rowN > 1) {
          for(var i = 0; i<rowN;i++){
            var temp = "";  //每次截取的字符串
            var start = i * max;  //开始截取的位置
            var end = start + max;  //结束截取的位置
            temp = item.substring(start,end)+ "\n";
            ret += temp;  //最终的字符串
          }
          this.monthlySalesData.xAxis.data.push(ret)
        } else {
          this.monthlySalesData.xAxis.data.push(item)
        }
      })
      this.monthlySales = this.$echarts.init(document.getElementById("monthlySales"));
      res.data.sale_trend.map((o) => this.monthlySalesData.series[0].data.push(Number(o.money)));
      let saleArr = [];
      res.data.sale_trend.map((o) => saleArr.push(o.sale));
      // this.monthlySalesData.yAxis[1].max = saleArr.sort()[saleArr.length - 1] * 2;
      this.monthlySalesData.series[1].data = saleArr;
      this.goodTable = res.data.goods_data;
    },
  },
  created() {},
  mounted() {
    this.tendencyChart = this.$echarts.init(document.getElementById("tendencyChart"));
    this.salesTrend = this.$echarts.init(document.getElementById("salesTrend"));
    this.monthlySales = this.$echarts.init(document.getElementById("monthlySales"));
    this.categorySales = this.$echarts.init(document.getElementById("categorySales"));
    this.getProductDetail();
    setTimeout(() => {
      this.tendencyChart.setOption({ ...this.option, ...this.tendencyChartData });
      this.salesTrend.setOption({ ...this.option, ...this.salesTrendData });
      this.monthlySales.setOption({ ...this.option, ...this.monthlySalesData });
      this.categorySales.setOption(this.categorySalesData);
      if (this.tendencyChartData.series[0].data) this.loadingVal = false;
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .echart-box {
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 30px;
    padding: 30px;
  }
  .echarts-box {
    display: flex;
    > div {
      background: #ffffff;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      height: 500px;
      padding: 24px;
    }
    > div:nth-child(1) {
      flex: 1;
      margin: 0 30px 30px 0;
    }
    > div:nth-child(2) {
      width: 500px;
    }
    > span:nth-child(1) {
      flex: 1;
      margin-right: 30px;
    }
    > span:nth-child(2) {
      width: 500px;
    }
  }
  .table-box {
    padding: 30px;
    display: flex;
    flex-direction: column;
    .table-item {
      // flex: 1;
      display: flex;
      height: 90px;
      > div:nth-child(1) {
        width: 36px;
        // margin-right: 24px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div:nth-child(2) {
        flex: 1;
        padding: 10px;
        display: flex;
        overflow: hidden;
      }
      .img {
        width: 70px;
        border-radius: 8px;
        margin-right: 10px;
      }
      .text-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #86909c;
        overflow: hidden;
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .red-text {
    color: #ff1743;
  }
}
</style>
